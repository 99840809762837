@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
:root {
    --primaryColor:#2B6EB5;
    --bs-primary-rgb:43,110,181;
    --primaryHoverColor:#327ac6;
    --primaryOptLessColor:rgba(43,110,181,0.4);
    --secondaryColor:#ec691f;
    --bs-success-rgb:26,177,107;
    --bs-info-rgb:166,8,205;
    --bs-pink-rgb:253,62,184;
    --bs-link-color:#327ac6;
}
.table-white {
    --bs-table-color: #000;
    --bs-table-bg: #FFF;
    --bs-table-border-color: #bacbe6;
    --bs-table-striped-bg: #FFF;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bacbe6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfd1ec;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
}
.table{--bs-table-striped-bg:rgb(234, 241, 248,0.35);}
body{font-size:0.90rem;background:#f4f6fd;}
strong,.bold{font-family: 'Roboto', sans-serif;font-weight:500;}
a{text-decoration:none;color:var(--primaryColor);}
a:hover{color:var(--primaryHoverColor);}
.btn-link{text-decoration:none;}
.op15{--bs-bg-opacity:.15;}
label, .semibold, th{font-weight:500;}
img{max-width:100%;}
.bg-pink{background-color:rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;}
.text-pink {--bs-text-opacity: 1; color: rgba(var(--bs-pink-rgb), var(--bs-text-opacity)) !important;}
.badge{font-weight:400;padding:5px 10px;font-size:0.75rem;}
.stargold {color:#f8b301;}
.starblank{color:#ccc;}
.fn12{font-size:12px;}
.fn13{font-size:13px;}
.fn14{font-size:14px;}
.fn15{font-size:15px;}
.fn16{font-size:16px;}
.fn18{font-size:18px;}
.mb-np5{margin-bottom:-5px;}
.form-control,.form-select, .input-group-text{color:#666666;border-color:#f2f2f2;}
.form-control:focus,.form-select:focus, .outLineInput:focus{box-shadow: 0 0 0 .20rem var(--primaryOptLessColor);border-color:var(--primaryColor);}

.bulletList{list-style-type:none;padding:0px;margin:0px;}
.bulletList li:before{content:"";width:5px;height:5px;background-color:#333;display:inline-block; vertical-align:top;border-radius:100%;margin:8px 4px 0 0px;}

.btn-primary{background-color:var(--primaryColor);border-color:var(--primaryColor);}
.btn-primary:hover,.btn-check:focus+.btn-primary,.btn-primary:focus{background-color:var(--primaryHoverColor) !important;border-color:var(--primaryHoverColor) !important;}
.btn-primary:focus,.btn-primary.focus {box-shadow: 0 0 0 .2rem var(--primaryOptLessColor)}
.btn-primary.disabled,.btn-primary:disabled {background-color: #7cc; border-color: #5bc2c2}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {background-color:var(--primaryHoverColor);border-color:var(--primaryHoverColor) !important;}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {box-shadow: 0 0 0 .2rem var(--primaryOptLessColor)}
.text-primary{color:var(--primaryColor) !important;}
.cursor-pointer{cursor: pointer;}
.outLineInput, .outLineInput:hover{border:1px solid #ced4da !important;color:#666666 !important;}
.outLineInput:after {right:10px; top:50%; transform:translateY(-50%); content:""; border-top:0.3em solid; border-right:0.3em solid transparent;  border-bottom:0; border-left:0.3em solid transparent; position:absolute;}
.btn-success{--bs-btn-bg:#11c16f;--bs-btn-border-color:#11c16f;}

.loginBg{background-image:url('../images/loginBg.png'), url('../images/loginBg1.jpg'); background-position:right bottom, right bottom; background-repeat:no-repeat;}
.forgotBg{background-image:url('../images/forgotBg.jpg'); background-position:right bottom; background-repeat:no-repeat;}
/* .loginBg{background:#FFF;} */
.topnav {position:fixed; top:0rem;left:0rem;right:0rem; padding-top:1rem;padding-bottom:1rem;z-index:1}
/* .navbar-brand img{max-height:3rem;} */
.btn-icon {padding:0; justify-content:center; overflow:hidden; border-radius:100%; flex-shrink:0; height:calc((0.700rem * 1) + (0.700rem * 2) + (2px)) !important; width:calc((0.700rem * 1) + (0.700rem * 2) + (2px)) !important;}
.toggleSmall{margin-left:75px;}
.toggleBig{margin-left:240px;}
.leftSidebar{width:15rem; z-index:1038;top:0;bottom:0px;left:0px;box-shadow:0.15rem 0 1.75rem 0 rgb(33 40 50 / 15%);background:#FFF;position:fixed;flex-direction:column; overflow-y:hidden;padding:0rem 0rem 1rem;transition:width 0.15s ease-in-out;}
.leftSidebar:hover{overflow-y:overlay;}
.leftSidebar::-webkit-scrollbar {width:0.75rem;background-color:transparent;}
.leftSidebar::-webkit-scrollbar-thumb {border-radius:10rem; border-width:0.2rem; border-style:solid; background-clip:padding-box; background-color: rgba(33, 40, 50, 0.2); border-color:transparent;}
.leftSidebar::-webkit-scrollbar-button {width:0;height:0; display:none;}
.leftSidebar .leftLink .nav-link{font-size:1rem; align-items:center; padding:0.90rem 1.2rem 0.90rem 1rem; position:relative; color:rgba(255,255,255,0.4); border-bottom:1px solid #2363a7;}
.leftSidebar .leftLink .nav-link:hover, .leftSidebar .leftLink .nav-link.active{color:#FFF;}

.rightSection{margin:4.2rem 0rem 0rem 15rem;transition:margin 0.15s ease-in-out;position:relative;}
.slideDiv .leftSidebar{/*transform:translateX(-11rem);*/width:4.1rem; text-align:center;}
.slideDiv .leftSidebar .leftLink .nav-link{padding:0.65rem 1.2rem 0.65rem 1rem;}
.slideDiv .leftSidebar .sideNavTxt{display:none;}
.slideDiv .leftSidebar .sideNavIcon{font-size:1.2rem;; margin-right:0px !important;}
.slideDiv .rightSection{margin-left:4rem;}
.logoSmall{display:none;}
.slideDiv .logoSmall{display:block;}
.slideDiv .logoBig{display:none;}

.pageHeading{margin-bottom:15px; color:var(--primaryColor);}
.pageHeading h2{font-weight:500;font-size:1.6rem;}
.cusDropdown{display:inherit;top:100%;}
.dropdown-menu{margin-top:7px !important;}
.dropdown-menu:before{content:""; display:block; position:absolute;left:15px;bottom:100%;width:0px;height:0px;border-left:8px solid transparent;border-right:8px solid transparent;border-bottom:8px solid #d6d6d6;}
.dropdown-menu-end:before{left:inherit;right:15px;}

.htlboxcol{width:100%; background:#FFF; margin-bottom:15px;box-shadow:0 2px 5px #e8e8e8; /*overflow:hidden;*/}
.htlboxcol .border-left,.htlboxcol .border-top{border-left-color:#f2f2f2 !important;border-top-color:#f2f2f2 !important;}
.sortTop{ background:#e6eef1; padding:8px 10px 4px; margin-bottom:15px; border:1px solid #dde2e4;}
.htlboxcol:hover{box-shadow:0 0 8px #d3d0d0;}

.hotelImg{min-width:250px;max-width:250px; overflow:hidden; position:relative; background:#f9f9f9;}
/* .hotelImg:after{content:'';position:absolute; left:0px;right:0px;top:0px;bottom:0px; background:-webkit-linear-gradient(0deg, rgba(255,255,255,0) 0%, #06448f 100%);background:linear-gradient(0deg, rgba(255,255,255,0) 0%, #06448f 100%);transition:all .5s ease 0s;opacity:0;z-index:1}
.hotelImg:before{content:'View Photos';position:absolute; left:0px;right:0px;bottom:100%;color:#FFF;z-index:2;text-align:center;text-shadow:0 0 5px rgba(0,0,0,0.5);transition:all .5s ease 0s;}
.hotelImg:hover:after{opacity:1;}
.hotelImg:hover:before{bottom:30%;} */
/* .hotelImg img{width:100%;position:absolute; left:0px;top:0px; text-align:center; filter:saturate(1.1) brightness(1) contrast(1.1);} */
.nameTitle{font-size:1.1rem;}

.ggallery{height:270px;}
.gImage{background-position:50%; background-repeat:no-repeat; background-size:cover;cursor:pointer;} 
.tabBorder .nav-link:hover, .tabBorder .nav-link:focus{color:var(--primaryColor);border-color:#FFF #FFF #dee2e6;}
.tabBorder .nav-link.active, .tabBorder .nav-item.show .nav-link{color:var(--primaryColor);border-color:#FFF #FFF var(--primaryColor);}

.nav-tabs .nav-link{border-bottom-width:3px;color:#000;}
.dp__flex_display .dp__instance_calendar .dp__calendar{border-left:1px solid #FFF;}
/* .dp__flex_display .dp__instance_calendar:first-child .dp__calendar{border-left:1px solid #FFF;} */
.dp__calendar_header{border-top:1px solid #ddd;}

.typeHeadDropdown .dropdown-item {font-size: 14px; border-bottom:1px solid rgba(0,0,0,.125); padding:0.4rem 1rem;}

/* .image-gallery-icon .image-gallery-svg{background-color: rgba(0, 0, 0, 0.45) !important; width:30px !important; height:30px !important; border-radius:100%;} */
.hotelImg .yarl__container{background-color:transparent;}
.hotelImg .yarl__slide{padding:0px;}

.fadeIn{animation: fadeInAnimation 2s ease-in-out 1s infinite; animation-iteration-count: 5; animation-fill-mode: forwards;}
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
.starCol svg{vertical-align:top;}

.fifthcol{width:50%; display:inline-block; vertical-align:top;}
.fifthcol:first-child .form-control{border-right:0px; border-top-right-radius:0px;border-bottom-right-radius:0px; position:relative;background-color:#FFF;}
.fifthcol:nth-child(2) .form-control{border-top-left-radius:0px;border-bottom-left-radius:0px;background-color:#FFF;}

.react-datepicker-popper{z-index:100 !important;}
.react-datepicker__day--outside-month {visibility:hidden !important; color:transparent !important; pointer-events:none; background-color:transparent !important;}
.designCalendar .react-datepicker__header{border:none; background-color:#FFF;}
.designCalendar .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .designCalendar .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{border-bottom-color:#FFF}
.designCalendar .react-datepicker__day-name, .designCalendar .react-datepicker__day, .designCalendar .react-datepicker__time-name{width:2rem;line-height:2rem;}
.designCalendar  .react-datepicker__current-month, .designCalendar  .react-datepicker-time__header, .designCalendar  .react-datepicker-year-header{padding:3px 0px;}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before{border-color:#216ba5 !important;width:12px !important;height:12px !important;}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{background-color:transparent !important;color:#000 !important;}

.multiSelectDiv .searchWrapper{padding:0px;border:none; border-radius:0px; min-height:auto;}
.multiSelectDiv > div{flex:1 1 auto; width:1%;}
.multiSelectDiv .highlightOption{background-color: rgba(var(--bs-primary-rgb));}
.multiSelectDiv .multiSelectContainer li:hover{background:var(--primaryHoverColor);}
.multiSelectDiv .multiSelectContainer input{margin-top:0px;}

.bgDiv{color:#FFF; background-position:center center; background-repeat:no-repeat; background-size:cover;}
.bg1{background-image:url('../images/bg1.png');}
.bg2{background-image:url('../images/bg2.png');}
.bg3{background-image:url('../images/bg3.png');}
.bg4{background-image:url('../images/bg4.png');}
.roundIcon{width:42px; height:42px;border-radius:50%; background:#FFF; justify-content: center; align-items: center; display: flex; font-size: 18px;}
.bg1 .roundIcon{color:#2B6EB5;}
.bg2 .roundIcon{color:#A32828;}
.bg3 .roundIcon{color:#0C8879;}
.bg4 .roundIcon{color:#660EAA;}

.recharts-legend-item .recharts-surface{width:20px !important; height:20px !important;}
.recharts-legend-item-text{color:#000 !important;}

@media only screen and (min-width: 240px) and (max-width:960px) {
.rightSection{margin-left:0rem;}
.rightSection:before{content:'';position:absolute;left:0rem;right:0rem;top:0rem;bottom:0rem;background:rgba(0,0,0,0.5);z-index:111;}
.slideDiv .rightSection:before{display:none;}
.navbar-brand img{max-width:15rem;}
}
@media only screen and (min-width: 240px) and (max-width:767px) {
.d-noneSmall{display:none !important;}
.slideDiv .leftSidebar{width:0px;}
.slideDiv .rightSection{margin-left:0px;}
.toggleSmall{margin-left:0px;}
.topnav{padding-top:0.7rem;padding-bottom:0.7rem;z-index:999;}
.htlboxcol .border-left.col-md-2{border-left:none !important;border-right:none !important;}
.hotelImg{width:100%;height:150px;max-width:100%;max-height:150px;}
.nameTitle{font-size:1rem;}
.lg-thumb-outer{display:none !important;}
.ggallery{height:130px;}
/* .hotelImg img{position:inherit;transform:inherit;top:inherit;}    */
}